import { getBasePath } from "./location.service";

type StateValue = string | number | boolean | object | StateValue[];

export enum StateKey {
  MICRO_SHELL_APP_NAME = "micro-shell:app-name",
  MICRO_SHELL_BASE_BATH = "micro-shell:base-path",
  MICRO_SHELL_APP_ALWAYS_ALIVE = "micro-shell:apps-always-alive",
  EVENT_BUS_APP = "event-bus:app",
}

const state = new Map<StateKey, StateValue>();

export function getStateValue<T extends StateValue>(key: StateKey): T {
  return state.get(key) as T;
}

export function setStateValue<T extends StateValue>(key: StateKey, value: T) {
  state.set(key, value);
}

export function setBootstrapValues({ name }: { name: string }) {
  setStateValue(StateKey.MICRO_SHELL_APP_NAME, name);
  setStateValue(StateKey.MICRO_SHELL_BASE_BATH, getBasePath());
}
