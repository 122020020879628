import { addImportMap } from "./dom.service";
import { registerFeatures } from "./features.service";
import { getBasePath } from "./location.service";
import { getParams } from "./params.service";
import { generatedProps } from "./props.service";
import {
  activityFnCreator,
  addApplication,
  defActivityFn,
  removeApplication,
} from "./single-spa.service";
import { StateKey, getStateValue, setStateValue } from "./state.service";

export async function registerApps() {
  const { apps, features } = await getParams();

  addImportMap(createImportMap(apps));

  registerMfs(apps);
  registerFeatures(features);
}

export function removeMfsAlwaysAlive() {
  const appsNames = getStateValue<string[]>(
    StateKey.MICRO_SHELL_APP_ALWAYS_ALIVE
  );
  const promises = appsNames.map((appName) => removeApplication(appName));
  return Promise.all(promises);
}

function registerMfs(apps: App[]) {
  addMfsAlwaysAlive(apps);
  addMfs(apps);
}

const createImportMap = (apps: App[]) => ({
  imports: apps.reduce<Record<string, string>>(
    (p, c) => ({ ...p, [c.name]: c.url }),
    {}
  ),
});

function addMfsAlwaysAlive(apps: App[]) {
  const appsNames = [];
  apps
    .filter((app) => app.type === "mf-always-alive")
    .forEach((app: AppMfAlwaysAlive) => {
      appsNames.push(app.name);
      addApplication({
        activityFn: defActivityFn,
        appName: app.name,
      });
    });
  setStateValue(StateKey.MICRO_SHELL_APP_ALWAYS_ALIVE, appsNames);
}

function addMfs(apps: App[]) {
  const basePath = getBasePath();
  apps
    .filter((app) => app.type === "mf")
    .forEach((app: AppMf) => {
      addApplication({
        activityFn: activityFnCreator(basePath, app),
        appName: app.name,
        extraProps: getExtraProps(app),
      });
    });
}

function getExtraProps(app: AppMf): object {
  let extraProps = {};
  if (app.generatedProps) {
    app.generatedProps.forEach((prop) => {
      if (prop in generatedProps) extraProps[prop] = generatedProps[prop];
    });
  }

  if (app.extraProps) extraProps = { ...extraProps, ...app.extraProps };

  return extraProps;
}
