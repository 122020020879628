import {
  ActivityFn,
  RegisterApplicationConfig,
  registerApplication,
  unregisterApplication,
} from "single-spa";
import { getStateValue, setStateValue } from "./state.service";
import { getBasePath } from "./location.service";

type ApplicationConfig = {
  appName: string;
  activityFn: ActivityFn;
  extraProps?: object;
};
type InternApplicationConfig = RegisterApplicationConfig;

export function addApplication({
  appName,
  activityFn,
  extraProps,
}: ApplicationConfig) {
  const basePath = getBasePath();
  registerApplication(appName, importFactory(appName), activityFn, {
    microShellState: {
      getStateValue,
      setStateValue,
    },
    basePath,
    ...extraProps,
  });
}

export function addInternApplication(cfg: InternApplicationConfig) {
  registerApplication(cfg);
}

export const importFactory = (appName: string) => () =>
  System.import(appName) as any;

export function removeApplication(appName: string) {
  return unregisterApplication(appName);
}

export const defActivityFn = () => true;
export const activityFnCreator =
  (basePath: string, app: Pick<AppMf, "paths">) => (l: Location) =>
    app.paths.some((path) => l.pathname.startsWith(`${basePath}${path}`));
