import { MOUNTED } from "single-spa";
import { StateKey, getStateValue } from "./state.service";
import { createAppDom } from "./dom.service";
import { removeMfsAlwaysAlive } from "./apps.service";

function spaEventHandler(
  event: CustomEvent<{ newAppStatuses: Record<string, string> }>
) {
  for (const key in event.detail.newAppStatuses) {
    const status = event.detail.newAppStatuses[key];
    if (status === MOUNTED) {
      createAppDom(getStateValue<string>(StateKey.MICRO_SHELL_APP_NAME), key);
    }
  }
}

export function registerBeforeMount() {
  window.addEventListener(
    "single-spa:before-mount-routing-event",
    spaEventHandler
  );
}

export async function unregisterBeforeMount() {
  window.removeEventListener(
    "single-spa:before-mount-routing-event",
    spaEventHandler
  );
  await removeMfsAlwaysAlive();
}
