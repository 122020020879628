export const APP_NAME = "@bavv-vivienda/channel-mock";
export const APP_PATHS = ["/demo"];
export const CHANNEL_EVENT_BUS = {
  EVENTS: {
    DISPATCH: "ADLDispatchEventBus",
  },
  TOPICS: {
    BASIC: "basicCustomerInformation",
  },
};
export const NEXT_PATH = "/datos-iniciales";
export const LOADER_SELECTOR = "avv-loader";
