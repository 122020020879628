import { setBootstrapValues } from "./services/state.service";
import {
  registerBeforeMount,
  unregisterBeforeMount,
} from "./services/handlers.service";
import { registerApps } from "./services/apps.service";
import { loadEventBus } from "./services/event-bus.service";
import { getBasePath } from "./services/location.service";
import { navigateToUrl } from "single-spa";

export async function bootstrap(props) {
  setBootstrapValues(props);
  await registerApps();
  await loadEventBus();
  registerBeforeMount();
}

export const mount = async () => {
  const basePath = getBasePath();
  const path = window.location.pathname.replace(/\/$/, "");
  if (basePath === path) navigateToUrl(`${basePath}/datos-iniciales`);
};

export async function unmount(_props) {
  await unregisterBeforeMount();
}
